.backgroundImg{
    position:fixed;
    top: calc(0vh + 100px);
    
    width:100%;
}

.mainCointainer{
    margin-top: 10vh;
    min-height:  calc(90vh - 68px);  
    padding-top: 10vh;
}


.toggleSwitchInMain{
    display: none;
    position: relative;
    margin-right: 15px;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);
    
}

.epochEndMsgContainer{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    min-width: 340px;   
    width: 980px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: #323535;
    background-color: #271e36;
    padding: 15px;
    margin-bottom: 10px;
}

.walletAndStakingParentContainer{
    overflow: auto;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    min-width: 340px;   
    width: 1000px;
    min-height: 255px;
}


.walletContainer{
    position: relative;
    max-width: 480px;
    float: left;
    
    min-width: 460px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: #323535;
    background-color: #271e36;
    padding: 15px;
    margin-bottom: 10px;
    
}

.walletAndEpochContainer{
    position: relative;
    max-width: 480px;
    float: left;
    
    min-width: 460px;
    /*padding: 15px;
    margin-bottom: 10px;*/
}

.walletContainer2{
    /*position: relative;*/
    max-width: 480px;
    
    min-width: 460px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: #323535;
    background-color: #271e36;
    padding: 15px;
    margin-bottom: 25px;
    
}

.epochContainer{
    /*position: relative;*/
    max-width: 480px;
    
    min-width: 460px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: #323535;
    background-color: #271e36;
    padding: 15px;
    margin-bottom: 10px;
    
}

.epochTimer{
    display:flex;
    text-align:center;
    justify-content:center;
}

.epochTimerDigit{
    padding-right: 8px;
}

.epochTimerDigitSeparator{
    line-height: 45px;
    padding-right: 8px;
}

.stakingContainer{
    position: relative;
    max-width: 480px;
    float: right;
    min-width: 460px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: #323535;
    background-color: #271e36;
    padding: 15px;
}

.rafflesWindowContainer{

    overflow: auto;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    min-width: 340px;   
    width: 80%;/*1280px;
    /*min-height: 255px;*/

    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: var(--container-borderColor);
    background-color: var(--major-color);  
    
    padding: 10px;
    padding-top:20px;
    padding-bottom: 20px;
    padding-right: 20px;
    margin-top: 10px;
    margin-bottom: 50px;

}


.walletSNFTsDisplayContainer{

    overflow: auto;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    min-width: 340px;   
    width: 980px;
    /*min-height: 255px;*/

    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: var(--success-borderColor);
    background-color: var(--major-color);  
    
    padding: 10px;
    padding-top:20px;
    padding-bottom: 20px;
    padding-right: 20px;
    margin-top: 10px;
    margin-bottom: 50px;

}

.walletSNFTsDisplayTitle{
    overflow: auto;
    margin-bottom: 15px;
}

.textContainer{
    position: relative;
    max-width: 880px;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color:var(--container-borderColor);
    background-color: var(--major-color);
    padding: 10px;
}

.textContainer p{
    padding-left: 10px;
}

.walletDataArea{
    font-size: 14px;
    position: relative;
    color: var(--major-textColor);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 15px;
}

.walletDataArea:hover{
    background-color: var(--type2btn-hoverColor);
    color: var(--type2btn-hoverFontColor);
}

.walletDataValue{
    position: relative;
    
    float: right;
}

.raffleWindowLeftSide{
    position: relative;
    float: left;
    margin-bottom: 5px;
    margin-left: 20px;
    max-width: 480px;
}

.rafflesCollectionsListContainer{
    border-style:solid;
    border-width:1px;
    border-radius: 10px;
    border-color: var(--success-borderColor);
    background-color: #271e36;
    width: fit-content;
    min-width: 320px;

}

.rafflesCollectionsList{
    z-index: 2;
    position: absolute;
    
    max-width: 400px;
    overflow-y: auto;
    max-height: 445px;
    /*min-width: 240px;*/
    border-style:solid;
    border-width:1px;
    border-radius: 10px;
    border-color: var(--success-borderColor);
    background-color: #271e36;
    
    padding-top: 10px;
    padding-bottom: 10px;
    
}

.raffleWindowHeader{
    overflow:auto;
}

.raffleWindowTitleContainer{
    max-width: 300px;
    overflow: auto;
    float: left;
}

.nftSearchBarContainer{
    
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0px;
}

.nftSearchBar{
    
    
    margin-left: 0px;
    
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: var(--success-borderColor);
}

.nftSearchInput{
    width: 94%;
    height: 30px;
    background-color: transparent;
    border-style: none;
    color: var(--major-textColor);
}

.nftSearchInput:focus {
    border-style: none;
    border-color: transparent;
    outline: none;
}

.collectionSearchBarContainer{
    max-width: 400px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 0px;
}

.collectionSearchBar{
    
    
    margin-left: 0px;
    
    border-style: solid;
    border-radius: 5px;
    border-width: 1px;
    border-color: var(--success-borderColor);
}

.collectionSearchInput{
    width: 94%;
    height: 30px;
    background-color: transparent;
    border-style: none;
    color: var(--major-textColor);
}

.collectionSearchInput:focus {
    border-style: none;
    border-color: transparent;
    outline: none;
}

.createRaffleButton{
    float: right;
    background-color: var(--type1btn-color);
    border-color: rgb(0,0,0,0);
    border-width: 1px;
    color: var(--type1btn-fontColor);
    border-radius: 5px;
    border-style: solid;
    margin-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding: 5px 10px;
    width: 200px;
    font-size: 16px;
    font-weight:500;
    margin-left: auto;
}

.createRaffleButton:hover{
    background-color: var(--type1btn-hoverColor);
    color: var(--type1btn-hoverFontColor);
}

.projectsList{
    
    position: relative;
    max-width: 430px;
    float: left;
    
    min-width: 240px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: var(--success-borderColor);
    background-color: #271e36;
    
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 5px;
    margin-left: 20px;
}

.collectionDataArea{
    
    font-size: 14px;
    position: relative;
    color: var(--major-textColor);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 5px;
    /*min-width: 300px;*/
    /*max-width: 300px;*/
}

.collectionDataArea:hover{
    cursor: pointer;
    background-color: var(--type2btn-hoverColor);
    color: var(--type2btn-hoverFontColor);
}

.collectionDataName{
    line-height:40px;
    width: 400px;
}

.projectDataArea{
    overflow: auto;
    font-size: 14px;
    position: relative;
    color: var(--major-textColor);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 5px;
    
}

.projectDataArea:hover{
    cursor: pointer;
    background-color: var(--type2btn-hoverColor);
    color: var(--type2btn-hoverFontColor);
}



.projectDataName{
    line-height:40px;
    
}

.projectDataIcon{
    float: left;
    margin-right: 5px;
    border-radius: 20px;
}

.projectDataValue{
    float: right;
    position: relative;
}

.raffleWindowRightSide{
    position: relative;
    width: 900px;
    height: 564px;
    float: right;
    min-width: 390px;

}

.raffleFilterContainer{
    
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: var(--success-borderColor);
    margin-bottom: 5px;
    padding: 5px;
    display: flex;
}

.filterElement{
    padding-left: 10px;
    padding-right: 44px;
}

.rafflesDisplayArea{
    position: relative;
    /*width: 900px;*/
    /*max-height: 1200px;*/
    /*float: right;*/
    min-width: 390px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: var(--success-borderColor);
    background-color: #271e36;
    padding: 5px;
    overflow-y: scroll;
}


.assetsDisplayArea{
    position: relative;
    width: 660px;
    height: 504px;
    float: right;
    min-width: 390px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: var(--success-borderColor);
    background-color: #271e36;
    padding: 5px;
    overflow-y: scroll;
}

.collectionPool{
    overflow: auto  ;
    max-width: 610px;
    align-items: center;
    /*text-align: center;*/
    
   /* border-style: solid;
    border-color: #323535;
    border-width: 1px;
    border-radius: 5px;*/
}

.nftImage{
    padding: 5px;
    border-radius: 5px;
    
    
}

.nftRaffleParameter{
    text-align: left;
    padding-left: 5px;
}

.nftImageContainer{
    /*width: 200px;
    height: 200px;
    padding-top: 20px;*/
    
    
    /*max-width: 200px;
    /*height: 240px;*/
    border-radius: 5px;
    text-align: center;
    /*float:left;*/
    display:inline-table;
    margin: 10px;
}

.nftImageContainer:hover{
    background-color: var(--type2btn-hoverColor);
    
}

.nftImageContainer:hover .nftImage{
    color: #271e36;
}

.raffleContainer{
    /*width: 200px;
    height: 200px;
    padding-top: 20px;*/
    
    border-style: solid;
    border-width:1px;
    border-color: var(--success-borderColor);
    border-radius: 5px;
    max-width: 200px;
    
    text-align: center;
    display:inline-table;
    margin: 5px;
}

.raffleContainer:hover{
    background-color:  rgb(211, 184, 255,0.1);
    cursor: pointer;
}



.footerContainer{
    position:absolute;
    bottom:0;
    width:100%;
    margin-left: auto;
    margin-right: auto;
    border-style: solid;
    border-width:1px;
    border-color: var(--container-borderColor);
    background-color: var(--major-color);
    padding: 1px;
    text-align: center;

}

.sloganContainer{
    position:fixed;
    bottom: 0vh;
    width:100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 1%;
    border-color: var(--container-borderColor);
    background-color: var(--major-color);
}


.normalText{
    position: relative;
    color: var(--major-textColor);
    font-size: 15px;
    font-family: var(--shrooms-font);
}

.normalLink{
    color: var(--minor-color);
}

.normalLink:hover{
    cursor: pointer;
}

.mintContainer{
    
    position: relative;
    text-align: center;
    max-width: 20%;
    margin: auto;
    
    min-width: 260px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: var(--container-borderColor);
    background-color: var(--major-color);
    padding: 10px;
    padding-top:20px;
    padding-bottom: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
}

.mintText{
    font-size: 20px;
    color: var(--major-textColor);
    margin-top: 0.2rem;
    font-family: var(--shrooms-font);
}

.dropDownMenu{
    border-color: var(--rangeSelector-borderColor);
    background-color: var(--major-color);
    color: var(--major-textColor);
    border-radius: 5px;
    min-width: 40px;
    text-align: center;
}


.claimRewardsButton {
    position: relative;
    
    padding: 5px;
    margin-right: 5px;
    width: 100%;
    max-width: 300px;
    border-radius: 10px;

    background-color: var(--type1btn-color);
    border-style: solid;
    border-width: 1px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 18px;
    font-weight:600;
    color: var(--type1btn-fontColor);
    font-family: var(--shrooms-font);
}

.claimRewardsButton:hover{
    background-color: var(--type1btn-hoverColor);
    color: var(--type1btn-hoverFontColor);
}


.raffleWindowTitleSection{
    margin-top: 15px;
    height: 25px;
    width: 250px;
}
.raffleWindowTitle{
    height: 25px;
    position:absolute;
    display:inline-table;
    font-size: 18px;
    font-weight: bold;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);
}

.refreshIcon{
    position: relative;
    float: right;
    content: url("../refresh-icon.png");
    width: 25px;
}

.refreshIcon:hover{
    cursor: pointer;
    content: url('../refresh-icon-hover.png');
}



.enterRaffleButton{
    background-color: var(--type1btn-color);
    border-color: rgb(0,0,0,0);
    border-width: 1px;
    color: var(--type1btn-fontColor);
    border-radius: 5px;
    border-style: solid;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    width: 95%;
}
.enterRaffleButton:hover{
    background-color: var(--type1btn-hoverColor);
    color: var(--type1btn-hoverFontColor);
}

.marketPlaceLogo{
    border-radius: 5px;
    padding: 5px;
}
.marketPlaceLogo:hover{
    background: #555;
}

.checkBox{
    margin-top: 6px;
    position: relative;
    margin-right: 5px;
    float: left;
}

.qMark{
    color: white;
    font-size:2vh;
    vertical-align: super;
}

.qMark .tooltiptext {
    visibility: hidden;
    background-color: var(--type1btn-color);
    color: var(--type1btn-fontColor);
    border-radius: 10px;
    border-style: solid;
    border-color: var(--bubble-borderColor);
    border-width: 1px;
    font-size:2vh;
    position: absolute;
    left: 60%;
    z-index: 2;
    padding: 5px;
  }

.qMark:hover  {
    cursor: pointer;
  }

.spacerElement{
    height: 2hv;
}

.containerOfATMContainer{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    min-width: 340px;   
    width: 900px;
    overflow: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.atmScreen{
    
    position: relative;
    padding: 5px;
    padding-top: 15px;
    text-align: center;
    
    border-radius: 5px;
    
}

.option1Container{
    position: relative;
    max-width: 430px;
    float: left;
    
    min-width: 390px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: #323535;
    background-color: #271e36;
    padding: 5px;
    
    
}

.option2Container{
    position: relative;
    max-width: 430px;
    float: right;
    min-width: 390px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: #323535;
    background-color: #271e36;
    padding: 5px;
}

.option3Container{
    position:relative;
    margin-left: auto;
    margin-right: auto;
    
    max-width: 550px;
    min-width: 390px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: #323535;
    background-color: #271e36;
    padding: 10px;
    margin-bottom: 60px;
}

.getShroomButton{
    background-color: var(--type1btn-color);
    border-color: rgb(0,0,0,0);
    border-width: 1px;
    color: var(--type1btn-fontColor);
    border-radius: 5px;
    border-style: solid;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.getShroomButton:hover{
    background-color: var(--type1btn-hoverColor);
    color: var(--type1btn-hoverFontColor);
}

/* width */
::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background-color: transparent;

  }
  

  /* Track */
  ::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
    padding: 2px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    /*background: rgb(34, 19, 54);*/
    background-color: rgb(66, 66, 66);
    border-radius: 10px;
    margin: 2px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  /*scroll track corner*/
  ::-webkit-scrollbar-corner{
    background-color: transparent;
  }


@media screen and (max-width: 460px){
    .collectionSearchInput{
        width: 92%;
    }
    .nftSearchInput{
        width: 92%;
    }
}  

@media screen and (max-width: 530px){
    .createRaffleButton{
        float: unset;
        position: relative;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 912px){
    .raffleFilterContainer{
        display: block;
    }
    .filterElement{
        padding-left: 0;
        margin-top: 5px;
    }
}  


@media screen and (max-width: 1200px) {
    .filterElement{
        padding-left:6px;
        padding-right: 6px;
    }
    .backgroundImg{
        /*display: none;*/
        width: unset;
    }
    .epochEndMsgContainer{
        margin-bottom: 25px;
        border-color: rgb(194, 194, 194);
    }
    .walletContainer{
        
        margin-bottom: 25px;
        border-color: rgb(194, 194, 194);
    }
    .walletContainer2{
        
        margin-bottom: 25px;
        border-color: rgb(194, 194, 194);
    }
    .epochContainer{
        
        margin-bottom: 25px;
        border-color: rgb(194, 194, 194);
    }
    .stakingContainer{
        
        margin-bottom: 25px;
        border-color: rgb(194, 194, 194);
    }
    .walletSNFTsDisplayContainer{
        border-color: rgb(194, 194, 194);
    }
    .rafflesWindowContainer{
        border-color: rgb(194, 194, 194);
    }
    .mintContainer{   
      border-color: var(--main-borderColor);
    }
    .textContainer{
        border-color: var(--main-borderColor);
    }
    
}

@media screen and (max-width: 1200px) {
    .mainCointainer{
        margin-top: 20vh;
        min-height:  calc(83vh - 68px);   
        padding-top: 0vh; 
    }
    .textContainer{
        margin-top: 180px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .containerOfATMContainer{
        width: 100%;
    }
    .option1Container{
        float: none;
        margin-left: auto;
        margin-right: auto;
        min-width: 300px;
        margin-bottom: 30px;
    }
    .option2Container{
        float: none;
        margin-left: auto;
        margin-right: auto;
        min-width: 300px;
    }
    .epochEndMsgContainer{
        width: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
    .walletAndStakingParentContainer{
        width: 100%;
    }
    .walletContainer{
        float: none;
        margin-left: auto;
        margin-right: auto;
        min-width: 300px;
    }
    .walletAndEpochContainer{
        float: none;
        margin-left: auto;
        margin-right: auto;
        min-width: 300px;
    }
    .walletContainer2{
        float: none;
        margin-left: auto;
        margin-right: auto;
        min-width: 300px;
    }
    .epochContainer{
        float: none;
        margin-left: auto;
        margin-right: auto;
        min-width: 300px;
    }
    .stakingContainer{
        float: none;
        margin-left: auto;
        margin-right: auto;
        min-width: 300px;
    }
    .walletSNFTsDisplayContainer{
        width: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
    .rafflesWindowContainer{
        width: auto;
        margin-left: 10px;
        margin-right: 10px;
    }
    .projectsList{
        float: none;
        width: auto;
        height: 200px;
        margin-left: 10px;
        margin-right: 10px;
        overflow-y: scroll;
    }
    .rafflesCollectionsList{
        overflow-y: scroll;
        max-height: 200px;
        width: 92%;
        
    }
    .raffleWindowLeftSide{
        float: none;
        width: auto;
        max-width: none;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
        
    }
    .assetsDisplayArea{
        float: none;
        min-width:auto;
        width:auto;
    }
    .rafflesDisplayArea{
        float: none;
        min-width:auto;
        width:auto;
        /*height: 460px;*/
    }
    .raffleWindowRightSide{
        float: none;
        min-width:auto;
        width:auto;
        margin-left: 10px;
        margin-right: 10px;
        height: 654px;
    }
    .mintContainer{
        min-width: 330px;
    }
    .textContainer{
      min-width: 330px;
    }
}




@media screen and (max-width: 1200px) {
.collectionDataArea{
    max-width: unset;
}
.toggleSwitchInMain{
    display: block;
}
.backgroundImg{
    display: none;
    
}
.nftRaffleParameter{
    text-align: center;
}

}

@media screen and (max-width: 800px){
    .rafflesWindowContainer{
        margin-top: 180px;
    }
    .walletAndStakingParentContainer{
        margin-top: 180px;
    }
}