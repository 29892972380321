

.outSidePriceTableWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainWindow-Body {
    
    position: absolute;
    padding: 32px;
    width: 98%;
    max-width: 420px;
    border-radius: 15px;
    background-color: rgb(39, 30, 54);
    border-color: rgb(63, 48, 85);
    border-width: 1px;
    border-style: solid;
    
}



.connectWalletButton:hover{
    background-color: #d3cff8;
}

.mainWindow-Body .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-size: 18px;
    font-family: "ShroomsFont";
    color: rgb(235, 235, 235);
}
.mainWindow-Body .close-btn:hover {
  color: var(--minor-color);
}

.mainWindow-Body .menuTitle {
    position: relative;
    margin: 5px;
    text-align: center;
    padding-bottom: 10px ;
    font-family: "ShroomsFontLight";
    color: rgb(235, 235, 235);
}

.horizontal-center {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

.priceTable{
    margin-left:auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 5%;
    
    
}

.tbody { 
    
    padding: 10px;
    margin: 5px;
    
    
    
    font-size: 18px;
    border-color: #8177c9;
    font-family: "ShroomsFontLight";
    
  } 

  tr{
    width: 200px;
  }

  th, td {
      
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      text-align: center; 
      color: rgb(235, 235, 235);
      
      
  } 

  

  th { 
    background-color: rgb(69, 56, 90);color: rgb(255, 255, 255); line-height: 180%; font-weight: normal;
  } 
  th:nth-child(1){
    border-radius: 15px 0 0 0 ;
    
    
  }
  th:nth-child(3){
    border-radius: 0 15px 0 0 ;
  }
  tr:nth-child(2), tr:nth-child(4), tr:nth-child(6), tr:nth-child(8),tr:nth-child(10){
    background-color: rgb(39, 30, 54); line-height: 180%; 
  }

  tr:nth-child(10) td:nth-child(1){
    border-radius: 0 0 0 15px ;
  }
    tr:nth-child(10) td:nth-child(3){
    border-radius: 0 0 15px 0;
    }
tr:nth-child(3), tr:nth-child(5), tr:nth-child(7), tr:nth-child(9){
    background-color: rgba(51, 40, 70); line-height: 180%;
    }  


