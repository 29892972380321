
.outSideWarningWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainWarningWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainWarningWindow-Body {
    
    position: absolute;
    padding: 15px;
    padding-right:30px;
    padding-left: 30px;
    max-width: 440px;
    border-radius: 10px;
    background-color: var(--major-color);
    border-color: var(--warning-borderColor);
    border-width: 1px;
    border-style: solid;
    text-align: center;
}


.mainWarningWindow-Body .close-btn {
    position: absolute;
    top: 5px;
    right: 8px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-size: 18px;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);
}

.mainWarningWindow-Body .close-btn:hover {
    color: var(--minor-color);
}

.mainWarningWindow-Body .menuTitle {
    position: relative;
    margin: 5px;
    text-align: center;
    padding-bottom: 10px ;
    font-family: var(--shrooms-font);
    color: var(--major-textColor);
}

.horizontal-center {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .warningMsgFont{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
    color: var(--major-textColor);
    margin-bottom: 5%;
    margin-top: 10px;
    font-family: var(--shrooms-font);
}

.warningMsgFont-LeftAlign{
  position: relative;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
  color: var(--major-textColor);
  margin-bottom: 5%;
  margin-top: 20px;
  font-family: var(--shrooms-font);
}

.warningIcon{
  position: relative;
  margin-left:auto;
  margin-right: auto;
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
}

.warningMsgImage{
  width: 100%;
  position: relative;
  margin-left:auto;
  margin-right: auto;
}


.warningOkButton {
  position: relative;
  margin-top: 10px;
  padding: 5px;
  
  width: 25%;
  max-width: 280px;
  border-radius: 10px;
  background-color: var(--type1btn-color);
  border-color: rgb(0,0,0,0);
  border-style: solid;
  border-width: 1px;
  float:right;
  font-size: 18px;
  font-weight:600;
  color: var(--type1btn-fontColor);
  font-family: var(--shrooms-font);
}


.warningOkButton:hover{
  background-color: var(--type1btn-hoverColor);
  color: var(--type1btn-fontColor);
}

.warningCancelButton {
  position: relative;
  margin-top: 10px;
  padding: 5px;
  margin-left: 10px;
  width: 25%;
  max-width: 280px;
  border-radius: 10px;
  background-color: var(--type1btn-color);
  border-color: rgb(0,0,0,0);
  border-style: solid;
  border-width: 1px;
  float: right;
  font-size: 18px;
  font-weight:600;
  color: var(--type1btn-fontColor);
  font-family: var(--shrooms-font);
}

.warningCancelButton:hover{
  background-color: var(--type1btn-hoverColor);
  color: var(--type1btn-fontColor);
}

.warningLongButton {
  position: relative;
  margin-top: 15px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  /*width: 25%;*/
  max-width: 320px;
  min-width: 100px;
  border-radius: 10px;
  background-color: var(--type1btn-color);
  border-color: rgb(0,0,0,0);
  border-style: solid;
  border-width: 1px;
  
  font-size: 18px;
  font-weight:600;
  color: var(--type1btn-fontColor);
  font-family: var(--shrooms-font);
}

.warningLongButton:hover{
  background-color: var(--type1btn-hoverColor);
  color: var(--type1btn-fontColor);
}