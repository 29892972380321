
.outSideRaffleParametersWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}


.raffleParametersWindow-Body {
    
    position: absolute;
    padding: 16px;
    width:580px;
    max-width: 620px;
    border-radius: 15px;
    border-color: var(--success-borderColor);
    background-color: var(--major-color);
    border-width: 1px;
    border-style: solid;
    text-align: center;
    
}


.raffleParametersWindow-Body .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-size: 18px;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);

}
.raffleParametersWindow-Body .close-btn:hover {
  color: var(--minor-color);
}

.raffleParametersWindow-Body .menuTitle {
    position: relative;
    margin: 5px;
    text-align: center;
    padding-bottom: 5px ;
    font-family: var(--shrooms-font);
    color: var(--major-textColor);
}

  .raffleParametersWindowFont{
    position: relative;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
    color: var(--major-textColor);
    text-align: center;
    margin-bottom: 5%;
    font-family: var(--shrooms-font);
}

.raffleDetailsContainer{
  height: auto;
  overflow: auto;
}

.raffleParameters{
  text-align: left;
  max-width: 520px;
  
}

.raffleParameters-mobile{
  display: none;
  text-align: left;
  max-width: 480px;
  
}

.raffleParameter{
  margin-top: 20px;
  
}

.raffleDetail{
  margin-top: 10px;
  
}

.raffleParametersNFTImageContainer{
  float: right;
  
  
}

.raffleParametersNFTImage{
  height: 200px;
  max-width:200px ;
}

.raffleEntryAmount{
  width: 120px;
  border-radius: 5px;
  border-color: var(--success-borderColor);
  background-color: var(--major-color);
  color: var(--major-textColor);
  border-width: 1px;
  border-style: solid;
}


.raffleShareButton{
  position: relative;
  margin-left: 0px;
  margin-bottom: 5px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  
  max-width: 280px;
  border-radius: 10px;
  background-color: var(--type2btn-color);
  border-color: var(--popup-borderColor);
  border-style: solid;
  border-width: 1px;

  font-size: 14px;
  
  color: var(--type2btn-fontColor);
  font-family: var(--shrooms-font);
}
.raffleShareButton:hover{
  background-color: var(--type2btn-hoverColor);
  color: var(--type2btn-hoverFontColor);
}


.raffleOkButton {
  position: relative;
  margin-top: 10px;
  padding: 5px;
  
  width: 100px;
  max-width: 280px;
  border-radius: 10px;
  background-color: var(--type1btn-color);
  border-color: rgb(0,0,0,0);
  border-style: solid;
  border-width: 1px;
  float: right;
  font-size: 18px;
  font-weight:600;
  color: var(--type1btn-fontColor);
  font-family: var(--shrooms-font);
}


.raffleOkButton:hover{
  background-color: var(--type1btn-hoverColor);
  color: var(--type1btn-fontColor);
}

.raffleCancelButton {
  position: relative;
  margin-top: 10px;
  padding: 5px;
  margin-left: 10px;
  width: 100px;
  max-width: 280px;
  border-radius: 10px;
  background-color: var(--type1btn-color);
  border-color: rgb(0,0,0,0);
  border-style: solid;
  border-width: 1px;
  float: right;
  font-size: 18px;
  font-weight:600;
  color: var(--type1btn-fontColor);
  font-family: var(--shrooms-font);
}

.raffleCancelButton:hover{
  background-color: var(--type1btn-hoverColor);
  color: var(--type1btn-fontColor);
}

@media screen and (max-width: 800px) {
  .raffleParametersNFTImage{
    height: 120px;
    max-width:120px ;
  }
  .raffleParametersNFTImageContainer{
    float: none;
  }
  .raffleParametersWindow-Body{
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    
  }
}

@media screen and (max-width: 500px) {
  .raffleParameters{
    display: none;
  }
  .raffleParameters-mobile{
    display: block;
  }
  .raffleOkButton{
    float:left;
  }
}