.selectNFTMenu {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.selectNFTMenu-Body {
    
    position: relative;
    padding: 32px;
    /*width: 50%;*/
    /*width: 800px;*/
    width: 900px;
    max-height: 600px;
    /*max-width: 360px;
    max-height: 460px;*/
    border-radius: 10px;
    border-color: var(--success-borderColor);
    background-color: var(--major-color);
    border-style: solid;
    border-width: 1px;
}


.selectNFTMenu-Body .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-size: 18px;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);
}

.selectNFTMenu-Body .close-btn:hover {
    color: var(--minor-color);
}

.selectNFTMenu-Body .menuTitle {
    
    position: relative;
    margin: 5px;
    text-align: center;
    padding-bottom: 10px ;
    font-family: var(--shrooms-font);
    color: var(--major-textColor);
}

.nftPool{
    height: 400px;
    max-width: 900px;
    align-items: center;
    /*text-align: center;*/
    overflow-y: scroll;
    border-style: solid;
    border-color: var(--success-borderColor);
    border-width: 1px;
    border-radius: 5px;
}



.horizontal-center {
    margin: auto;
    position: absolute;
    /*left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);*/
  }

/* width */
::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
    padding: 2px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    /*background: rgb(34, 19, 54);*/
    background-color: rgb(66, 66, 66);
    border-radius: 10px;
    margin: 2px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


  @media screen and (max-width: 900px){
    .selectNFTMenu-Body {
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
      padding: 12px;
    }
  }

  @media screen and (max-width: 500px){
    .selectNFTMenu-Body {
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
      padding: 12px;
    }
  }