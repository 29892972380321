.outSidePopupWindow {
    z-index: 3;
    position:fixed;
    top:0vh;
    left:0vh;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}


.popupWindow-Body {
    
    position:absolute;
    top: 110px;
    right: 40px;
    padding: 16px;
    max-width: 360px;
    width:310px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: var(--popup-borderColor);
    background-color: var(--major-color);

    
    width: 100%;


}

.okButton {
    position: relative;
    
    padding: 5px;
    margin-right: 5px;
    width: 50%;
    max-width: 280px;
    border-radius: 10px;
    background-color: var(--type2btn-color);
    border-color: var(--popup-borderColor);
    border-style: solid;
    border-width: 1px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 18px;
    font-weight:600;
    color: var(--type2btn-fontColor);
    font-family: var(--shrooms-font);
}

.okButton:hover{
    background-color: var(--type2btn-hoverColor);
    color: var(--type2btn-hoverFontColor);
}


.msgFont{
    font-size: 14px;
    font-weight: 400;
    color: var(--major-textColor);
    text-align: center;
    margin-bottom: 5%;
    font-family: var(--shrooms-font);
}

@media screen and (max-width: 900px) {
    .popupWindow-Body {
        top: 150px;
        right:unset
    }
}
