
.outSideBufferWindow {
    z-index: 4;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainBufferWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainBufferWindow-Body {
    
    position: absolute;
    padding: 32px;
  text-align: center;
    min-width: 220px;
    /*width:240px;*/
    border-radius: 15px;
    border-color: var(--buffer-borderColor);
    background-color: var(--major-color);
    border-width: 1px;
    border-style: solid;
    
}

.bufferMsgFont{
    position: relative;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
    color: var(--buffer-textColor);
    text-align: center;
    margin-bottom: 5%;
    font-family: var(--shrooms-font);
}


.bufferIcon{
  position: relative;
  margin-left:auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.bufferMsgImage{
  width: 100%;
  position: relative;
  
  margin-left:auto;
  margin-right: auto;
}
