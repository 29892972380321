.tokensMenu {
    z-index: 3;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.tokensMenu-Body {
    
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 360px;
    border-radius: 10px;
    background-color: var(--major-color);
    border-color: var(--walletMenu-borderColor);
    border-style: solid;
    border-width: 1px;
}

.tokensMenu-Body .selectTokenButton {
    position: relative;
    
    padding: 10px;
    margin: 5px;
    width: 100%;
    max-width: 280px;
    border-radius: 10px;
    background-color: var(--type2btn-color);
    font-size: 20px;
    border-style: solid;
    border-color: var(--walletMenu-buttonsBorderColor);
    border-width: 1px;
    color: var(--type2btn-fontColor);
    font-family: var(--shrooms-font);
    
}

.tokensMenu-Body .selectTokenButton:hover{
    background-color: var(--type2btn-hoverColor);
    color: var(--type2btn-hoverFontColor);
}

.tokensMenu-Body .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-size: 18px;
    font-weight: bold;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);
}

.close-btn:hover{
    color: var(--minor-color);
}


.tokensMenu-Body .menuTitle {
    position: relative;
    margin: 5px;
    text-align: center;
    padding-bottom: 10px ;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);
}


