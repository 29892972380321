
.outSideTermsAndConditionsWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.termsAndConditionsWindow-Body {
    
    position: absolute;
    padding: 32px;

    max-width: 420px;
    border-radius: 15px;
    border-color: var(--success-borderColor);
    background-color: var(--major-color);
    border-width: 1px;
    border-style: solid;
    text-align: center;
    
}


.termsAndConditionsWindow-Body .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-size: 18px;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);

}
.termsAndConditionsWindow-Body .close-btn:hover {
  color: var(--minor-color);
}

.termsAndConditionsWindow-Body .menuTitle {
    position: relative;
    margin: 5px;
    text-align: center;
    padding-bottom: 10px ;
    font-family: var(--shrooms-font);
    color: var(--major-textColor);
}

.termsAndConditionsText{
  position: relative;
  color: var(--major-textColor);
  font-size: 15px;
  font-family: var(--shrooms-font);
  text-align: justify;
  padding: 15px;
  max-height: 500px;
  overflow-y: scroll;
  border-radius: 10px;
  border-style: solid;
  border-color: var(--success-borderColor);
  border-width: 1px;
}

@media screen and (max-width: 500px){
  .termsAndConditionsWindow-Body{
    margin-left:10px;
    margin-right: 10px;
  }
  .termsAndConditionsText{
    max-height: 280px;
  }
}