

.outSideDropDown {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}


.walletDropDown-Body {
    
    position: absolute;
    top: 74px;
    right: 40px;
    padding: 16px;
    max-width: 360px;
    width:310px;
    border-style: solid;
    border-width:1px;
    border-radius: 10px;
    border-color: var(--walletDropDown-borderColor);
    background-color: var(--major-color);
}

.changeWalletButton {
    position: relative;
    
    padding: 5px;
    margin-right: 5px;
    width: 100%;
    max-width: 280px;
    border-radius: 10px;
    border-color: var(--walletDropDown-borderColor);
    background-color: var(--type2btn-color);
    border-style: solid;
    border-width: 1px;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    font-size: 18px;
    font-weight:600;
    color: var(--type2btn-fontColor);
    font-family: var(--shrooms-font);
}

.changeWalletButton:hover{
    background-color: var(--type2btn-hoverColor);
    color: var(--type2btn-hoverFontColor);
}

.walletIcon {
    position:relative;
    padding: 5px;
    margin-right: 20px;
    
    float: left;
    border-style: solid;
    border-radius: 10px;
    border-width: 1px;
    border-style: none;

    background-color: var(--major-color);
}

.walletAssetsArea{
    position: relative;
    max-height: 800px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 2px;
    padding-left: 2px;
   
}

.walletAssetArea{
    font-size: 14px;
    position: relative;
    font-weight: bold;
    color: var(--major-textColor);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 2px;
    padding-left: 2px;
}

.walletAssetArea:hover{
    background-color: var(--type2btn-hoverColor);
    color: var(--type2btn-hoverFontColor);
}

.walletAssetIdentity{
    position: relative;
    float: left;
}

.walletAssetQuantity{
    position: relative;
    
    float: right;
}

.walletNameFont{
    
    font-size: 18px;
    font-weight: bold;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);
}

.addressFont{
    font-size: 14px;
    font-weight: 400;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);
}

.balanceFont{
    font-size: 16px;
    font-weight: bold;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);
}

.horizontalLine{
    color: var(--major-textColor);
    background-color: var(--major-textColor);
    padding: 0px;
    margin-top:0px;
  
}