.mainMenuPage{
    z-index:5;
    position: relative;
    height: 100vh;
    overflow: auto;
    background-color: var(--major-color);
}

.platformSelectTitle{
    position: relative;
    margin-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    color: var(--title-color);
    font-size: 35px;
    font-weight: bold ;
    font-family: var(--shrooms-font);
}


.platformMenu{
    /*display:inline-flex;*/
    max-width: fit-content;
    margin-top: 50px;
    position: relative;
    margin-left: auto;   
    margin-right: auto;
}

.platformSelectButton{
    display: inline-table;
    padding: 20px;
    margin: 20px;
    border-radius: 15px;
    border-color: var(--success-borderColor);
    border-width: 1px;
    border-style: solid;
}

.platformSelectButton:hover{
    background-color: var(--type2btn-hoverColor);
    cursor: pointer;
}


.platformSelectText{
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    color: var(--major-textColor);
    font-size: 18px;
    font-family: var(--shrooms-font);
}
.platformSelectButton:hover .platformSelectText {
    color: #271e36;
}

@media screen and (max-width: 1000px) {
    .platformSelectTitle{
        margin-top: 40px;
    }
    .platformMenu{
        max-width:min-content;
    }
}



.header-container {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100px;
    background-color: var(--header-backgroundColor);
    
    display: flex;
    justify-content: center;
    border-bottom: 1px;
    border-bottom-style: solid;
    border-bottom-color: #323535;
}

.toggleSwitchInHeader{
    position: absolute;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);
    right: 280px;
    top: 46%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    
}

.connectBtn {
    position: absolute;
    
    right: 40px;
    padding: 5px;
    padding-right: 20px;
    padding-left: 20px;
    border-radius: 10px;
    font-size: 18px;
    border:1px;
    border-style: none;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: var(--type1btn-color);
    color: var(--type1btn-fontColor);
    font-family: var(--shrooms-font);
    font-weight:700;
}

.connectBtn:hover{
    background-color: var(--type1btn-hoverColor);
    color: var(--type1btn-hoverFontColor);
}

.tokenSelect{
    position: absolute;
    left: 350px;
    color: var(--major-textColor);
    border:1px;
    border-style:solid;
    border-color: var(--walletMenu-buttonsBorderColor);/* #3d4242;*/
    top: 55%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: var(--shrooms-font);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 5px;
}

.tokenSelect:hover{
    background-color: var(--type2btn-hoverColor);
    color: var(--type2btn-hoverFontColor);
    cursor: pointer;
}

.switchDashboard{
    position: absolute;
    left: 200px;
    
    height: 42px;
    line-height: 26px;
    color: var(--major-textColor);
    border:1px;
    border-style:solid;
    border-color: var(--walletMenu-buttonsBorderColor);/* #3d4242;*/
    top: 55%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: var(--shrooms-font);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 5px;
}

.switchDashboard:hover{
    background-color: var(--type2btn-hoverColor);
    color: var(--type2btn-hoverFontColor);
    cursor: pointer;
}


.siteTitle {
    position: absolute;
    margin: 5px;
    text-align: center;
    padding-bottom: 10px ;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: var(--title-color);
    font-size: 35px;
    font-weight: bold ;
    font-family: var(--shrooms-font);
}

.siteLogo {
    position: absolute;
    
    left: 40px;

    font-size: 18px;
    border:1px;
    border-style:none;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    
}




@media screen and (max-width: 800px) {
    .header-container{
        height: 145px;
    }
    .connectBtn{
        right: 20px;
        top: 25%;
        -ms-transform: translateY(-35%);
        transform: translateY(-35%);
    }
    .toggleSwitchInHeader{
        display: none;
    }
    .siteLogo{
        left:10px;   
        width: 70px;
        height: 70px;
        top: 25%;
        -ms-transform: translateY(-35%);
        transform: translateY(-35%);
    }
    .siteTitle{
        display: none;
        font-size: 20px;
    }
    .tokenSelect{
        left: 55%;
        top:80%;
    }
    .switchDashboard{
        left:10px;
        top:80%;
    }
}