
.outSideSuccessWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainSuccessWindow {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);

    display: flex;
    justify-content: center;
    align-items: center;
}

.mainSuccessWindow-Body {
    
    position: absolute;
    padding: 32px;

    max-width: 420px;
    border-radius: 15px;
    border-color: var(--success-borderColor);
    background-color: var(--major-color);
    border-width: 1px;
    border-style: solid;
    text-align: center;
    
}


.mainSuccessWindow-Body .close-btn {
    position: absolute;
    top: 16px;
    right: 16px;
    border-radius: 20px;
    background-color: transparent;
    border-style: none;
    font-size: 18px;
    color: var(--major-textColor);
    font-family: var(--shrooms-font);

}
.mainSuccessWindow-Body .close-btn:hover {
  color: var(--minor-color);
}

.mainSuccessWindow-Body .menuTitle {
    position: relative;
    margin: 5px;
    text-align: center;
    padding-bottom: 10px ;
    font-family: var(--shrooms-font);
    color: var(--major-textColor);
}

.horizontal-center {
    margin: 0;
    position: absolute;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .successMsgFont{
    position: relative;
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
    font-weight: 400;
    color: var(--major-textColor);
    text-align: center;
    margin-bottom: 5%;
    font-family: var(--shrooms-font);
}


.successMsgImage{
  width: 100%;
  position: relative;
  height: 89%;
  margin-left: auto;
  margin-right: auto;
}

.successOkButton {
  position: relative;
  margin-top: 5%;
  padding: 5px;
  width: 50%;
  max-width: 280px;
  border-radius: 10px;
  background-color: var(--type1btn-color);
  border-color: rgb(0, 0, 0,0);
  border-style: solid;
  border-width: 1px;
  font-size: 18px;
  font-weight:600;
  color: var(--type1btn-fontColor);
  font-family: var(--shrooms-font);
}

.successOkButton:hover{
  background-color: var(--type1btn-hoverColor);
  color: var(--type1btn-fontColor);
}